import React from 'react';

const ScreenSizeContext = React.createContext({
  isScreenWidthLarge: false,
  isScreenWidthExtraLarge: false,
  isScreenWidthXSmall: false,
  innerWidth: 0,
});

export default ScreenSizeContext;
