import React, { Dispatch, SetStateAction } from 'react';
import { Item } from '../components/item-card/ItemCard';

export interface ItemsToCompareContextInterface {
  itemsToCompare: Item[];
  setItemsToCompare: Dispatch<SetStateAction<Item[]>>;
}

const ItemsToCompareContext =
  React.createContext<ItemsToCompareContextInterface>({
    itemsToCompare: [],
    setItemsToCompare: () => null,
  });

export default ItemsToCompareContext;
