import React, { useCallback } from 'react';
import {
  formatValue,
  Item,
  ItemDetails,
  openExternalLink,
  Price,
} from '../components/item-card/ItemCard';
import Image from 'react-bootstrap/Image';
import './ItemColumn.scss';
import Table from 'react-bootstrap/Table';
import {
  RetailerLogo,
  RetailerLogosName,
} from '../components/retailer-logo/RetailerLogo';
import ExpandableText from '../components/expandable-text/ExpandableText';

export const valueIsInvalid = (value: unknown): boolean => {
  return !value || value == 'null' || value == 'Unavailable' || value == 'Unknown';
};

export const ItemColumn = ({
  item,
  modal = false,
  firstColumn,
  rowsToHide,
}: {
  item: Item;
  modal?: boolean;
  firstColumn?: boolean;
  rowsToHide: (keyof ItemDetails)[];
}) => {
  const showRow = useCallback(
    (rowName: keyof ItemDetails) => {
      return !rowsToHide.includes(rowName);
    },
    [rowsToHide],
  );

  return (
    <div className="item-column">
      <div>

      
      <div
        className="item-image"
        onClick={() => openExternalLink(item.merchantUrl)}
      >
        <Image src={item?.details?.imageURL} />
      </div>
      <div className="item-price">
        <Price
          regularPrice={item?.details?.regularPrice?.toString() ?? ''}
          salePrice={item?.details?.salePrice?.toString() ?? ''}
          onClick={() => openExternalLink(item.merchantUrl)}
        />
      </div>
      <div
        className="item-name"
        // onClick={() => openExternalLink(item.merchantUrl)}
      >
        
        <ExpandableText text={item?.details?.name ?? ""}/>
      </div>
      </div>

      {!modal ? (
        <>
          <div onClick={() => openExternalLink(item.merchantUrl)}>
            <RetailerLogo
              websiteCode={item?.details?.websiteCode as RetailerLogosName}
            />
          </div>
          {showRow('screenResolution') && (
            <div className="item-compare-detail">
              {firstColumn && (
                <div className="sub-header">Screen Resolution</div>
              )}
              <span className="value">{formatValue(item?.details?.screenResolution)}</span>
            </div>
          )}
          {showRow('screenSize') && (
            <div className="item-compare-detail">
              {firstColumn && <div className="sub-header">Screen Size</div>}
              <span className="value">{formatValue(item?.details?.screenSize)}</span>
            </div>
          )}
          {showRow('cpuCores') && (
            <div className="item-compare-detail">
              {firstColumn && <div className="sub-header">CPU Cores</div>}
              <span className="value">{formatValue(item?.details?.cpuCores)}</span>
            </div>
          )}
          {showRow('osName') && (
            <div className="item-compare-detail">
              {firstColumn && <div className="sub-header">OS Name</div>}
              <span className="value">{formatValue(item?.details?.osName)}</span>
            </div>
          )}
          {showRow('supportedWireless') && (
            <div className="item-compare-detail">
              {firstColumn && (
                <div className="sub-header">Supported Wireless</div>
              )}
              <span className="value">{formatValue(item?.details?.supportedWireless)}</span>
            </div>
          )}
          {showRow('usb3Ports') && (
            <div className="item-compare-detail">
              {firstColumn && <div className="sub-header">USB 3 Ports</div>}
              <span className="value">{formatValue(item?.details?.usb3Ports)}</span>
            </div>
          )}
          {showRow('usb2Ports') && (
            <div className="item-compare-detail">
              {firstColumn && <div className="sub-header">USB 2 Ports</div>}
              <span className="value">{formatValue(item?.details?.usb2Ports)}</span>
            </div>
          )}
          {showRow('usbTypeCPorts') && (
            <div className="item-compare-detail">
              {firstColumn && (
                <div className="sub-header">USB Type C Ports</div>
              )}
              <span className="value">{formatValue(item?.details?.usbTypeCPorts)}</span>
            </div>
          )}
          {showRow('thunderbolt4Ports') && (
            <div className="item-compare-detail">
              {firstColumn && (
                <div className="sub-header">Thunderbolt 4 Ports</div>
              )}
              <span className="value">{formatValue(item?.details?.thunderbolt4Ports)}</span>
            </div>
          )}
          {showRow('thunderbolt3Ports') && (
            <div className="item-compare-detail">
              {firstColumn && (
                <div className="sub-header">Thunderbolt 3 Ports</div>
              )}
              <span className="value">{formatValue(item?.details?.thunderbolt3Ports)}</span>
            </div>
          )}
          {showRow('bluetooth') && (
            <div className="item-compare-detail">
              {firstColumn && <div className="sub-header">Bluetooth</div>}
              <span className="item-compare-detail">{formatValue(item?.details?.bluetooth)}</span>
            </div>
          )}
        </>
      ) : (
        <Table className="item-detail-table">
          <tbody>
           {!valueIsInvalid(formatValue(item?.details?.ramSize)) && (
            <tr>
              <td className="item-detail-first-columnn">RAM Size</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.ramSize)}GB
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.ramType)) && (
            <tr>
              <td className="item-detail-first-columnn">RAM Type</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.ramType)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.graphicsType)) && (
            <tr>
              <td className="item-detail-first-columnn">Graphics Type</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.graphicsType)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.gpuBrand)) && (
            <tr>
              <td className="item-detail-first-columnn">GPU Brand</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.gpuBrand)}
              </td>
            </tr>		  
			)}
			{!valueIsInvalid(formatValue(item?.details?.cpuSpeed)) && (
            <tr>
              <td className="item-detail-first-columnn">CPU Speed</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.cpuSpeed)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.cpuModelNumber)) && (
            <tr>
              <td className="item-detail-first-columnn">CPU Model Number</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.cpuModelNumber)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.cpuBrand)) && (
            <tr>
              <td className="item-detail-first-columnn">CPU Brand</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.cpuBrand)}
              </td>
            </tr>		  
			)}
			{!valueIsInvalid(formatValue(item?.details?.storageSize)) && (
            <tr>
              <td className="item-detail-first-columnn">Storage Size</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.storageSize)}GB
              </td>
            </tr>		  
			)}
			{!valueIsInvalid(formatValue(item?.details?.screenResolution)) && (
            <tr>
              <td className="item-detail-first-columnn">Screen Resolution</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.screenResolution)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.screenSize)) && (
            <tr>
              <td className="item-detail-first-columnn">Screen Size</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.screenSize)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.cpuCores)) && (
            <tr>
              <td className="item-detail-first-columnn">CPU Cores</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.cpuCores)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.condition)) && (
            <tr>
              <td className="item-detail-first-columnn">Condition</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.condition)}
              </td>
            </tr>			
			)}
			{!valueIsInvalid(formatValue(item?.details?.osName)) && (
            <tr>
              <td className="item-detail-first-columnn">OS Name</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.osName)}
              </td>
            </tr>			
			)}
			{!valueIsInvalid(formatValue(item?.details?.depthInInches)) && (
            <tr>
              <td className="item-detail-first-columnn">Depth In Inches</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.depthInInches)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.heightInInches)) && (
            <tr>
              <td className="item-detail-first-columnn">Height In Inches</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.heightInInches)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.widthInInches)) && (
            <tr>
              <td className="item-detail-first-columnn">Width In Inches</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.widthInInches)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.brand)) && (
            <tr>
              <td className="item-detail-first-columnn">Brand</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.brand)}
              </td>
            </tr>			
			)}
			{!valueIsInvalid(formatValue(item?.details?.wirelessProtocol)) && (
            <tr>
              <td className="item-detail-first-columnn">Wireless Protocol</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.wirelessProtocol)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.usb3Ports)) && (
            <tr>
              <td className="item-detail-first-columnn">USB 3 Ports</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.usb3Ports)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.usb2Ports)) && (
            <tr>
              <td className="item-detail-first-columnn">USB 2 Ports</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.usb2Ports)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.usbTypeCPorts)) && (
            <tr>
              <td className="item-detail-first-columnn">USB Type C Ports</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.usbTypeCPorts)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.thunderbolt4Ports)) && (			
            <tr>
              <td className="item-detail-first-columnn">Thunderbold 4 Ports</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.thunderbolt4Ports)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.thunderbolt3Ports)) && (			
            <tr>
              <td className="item-detail-first-columnn">Thunderbold 3 Ports</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.thunderbolt3Ports)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.thunderbolt2Ports)) && (			
            <tr>
              <td className="item-detail-first-columnn">Thunderbolt 2 Ports</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.thunderbolt2Ports)}
              </td>
            </tr>
			)}			
			{!valueIsInvalid(formatValue(item?.details?.bluetooth)) && (			
            <tr>
              <td className="item-detail-first-columnn">Bluetooth</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.bluetooth)}
              </td>
            </tr>
			)}
            { /* <tr>
              <td className="item-detail-first-columnn">Description</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.description)}
              </td>
            </tr> */}
			{!valueIsInvalid(formatValue(item?.details?.upc)) && (			
            <tr>
              <td className="item-detail-first-columnn">UPC</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.upc)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.sku)) && (			
            <tr>
              <td className="item-detail-first-columnn">SKU</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.sku)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.modelNumber)) && (			
            <tr>
              <td className="item-detail-first-columnn">Model Number</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.modelNumber)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.color)) && (			
            <tr>
              <td className="item-detail-first-columnn">Color</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.color)}
              </td>
            </tr>
			)}
            {/* <tr>
              <td className="item-detail-first-columnn">
                Retailers Disclaimer
              </td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.retailersDisclaimer)}
              </td>
            </tr> */}
			{!valueIsInvalid(formatValue(item?.details?.touchScreen)) && (			
            <tr>
              <td className="item-detail-first-columnn">Touch Screen</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.touchScreen)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.opticalDrives)) && (			
            <tr>
              <td className="item-detail-first-columnn">Optical Drivers</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.opticalDrives)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.ethernet)) && (						
            <tr>
              <td className="item-detail-first-columnn">Ethernet</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.ethernet)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.manufacturerWarrantyParts)) && (						
            <tr>
              <td className="item-detail-first-columnn">
                Manufacturer Warranty Parts
              </td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.manufacturerWarrantyParts)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.manufacturerWarrantyLabor)) && (
            <tr>
              <td className="item-detail-first-columnn">
                Manufacturer Warranty Labor
              </td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.manufacturerWarrantyLabor)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.ieee1394)) && (
            <tr>
              <td className="item-detail-first-columnn">IEEE1394</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.ieee1394)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.totalUSBPorts)) && (			
            <tr>
              <td className="item-detail-first-columnn">Total USB Ports</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.totalUSBPorts)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.osArchitecture)) && (
            <tr>
              <td className="item-detail-first-columnn">OS Architecture</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.osArchitecture)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.cameraHighestResolution)) && (
            <tr>
              <td className="item-detail-first-columnn">
                Camera Highest Resolution
              </td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.cameraHighestResolution)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.mic)) && (			
            <tr>
              <td className="item-detail-first-columnn">Mic</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.mic)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.mediaCardReader)) && (			
            <tr>
              <td className="item-detail-first-columnn">Media Card Reader</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.mediaCardReader)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.configurable)) && (			
            <tr>
              <td className="item-detail-first-columnn">Configurable</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.configurable)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.batteryLife)) && (			
            <tr>
              <td className="item-detail-first-columnn">Battery Life</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.batteryLife)}
              </td>
            </tr>
			)}
			{!valueIsInvalid(formatValue(item?.details?.audio)) && (			
            <tr>
              <td className="item-detail-first-columnn">Audio</td>
              <td className="item-detail-second-columnn">
                {formatValue(item?.details?.audio)}
              </td>
            </tr>
			)}
          </tbody>
        </Table>
      )}
    </div>
  );
};