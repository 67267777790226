import React from 'react';

export enum RetailerLogosName {
  BESTBUY = 'BB',
  WALMART = 'WM',
  DELL = 'DELL',
}

export const RetailerLogo = ({
  websiteCode,
}: {
  websiteCode: RetailerLogosName;
}) => {
  let fileName = '';
  switch (websiteCode) {
    case RetailerLogosName.BESTBUY:
      fileName = `${RetailerLogosName.BESTBUY}_logo.jpg`;
      break;
    case RetailerLogosName.WALMART:
      fileName = `${RetailerLogosName.WALMART}_logo.jpg`;
      break;
    case RetailerLogosName.DELL:
      fileName = `${RetailerLogosName.DELL}_logo.png`;
      break;
    default:
      break;
  }

  //   const fileName = `${websiteCode}_logo.png`;
  const src = `images/${fileName}`;
  return (
    <div className="logo-container">
      <img className="retailer-logo  d-block mx-auto" alt="" src={src} />
    </div>
  );
};