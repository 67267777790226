import React, { useCallback, useContext } from 'react';
import { Item } from '../item-card/ItemCard';
import './Selection.scss';
import { BsFillXCircleFill } from 'react-icons/bs';
import Image from 'react-bootstrap/Image';
import ItemsToCompareContext from '../../contexts/ItemsToCompareContext';

export const Selection = ({ item }: { item: Item | undefined }) => {
  const { itemsToCompare, setItemsToCompare } = useContext(
    ItemsToCompareContext,
  );

  const removeItem = useCallback(() => {
    if (item?.sku) {
      const itemIndex = itemsToCompare.findIndex((i) => i.sku === item.sku);
      const auxItemsToCompare = itemsToCompare;
      auxItemsToCompare.splice(itemIndex, 1);
      setItemsToCompare([...auxItemsToCompare]);
    }
  }, [itemsToCompare]);

  return (
    <>
      {item ? (
        <div className="selection filled-selection">
          <BsFillXCircleFill onClick={removeItem} />
          <div className="rows">
            <Image src={item.imageUrl} />
            <div className="text">
              <div className="item-name">{item.itemName}</div>
              <div className="item-price">${item.salePrice}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="selection empty-selection" />
      )}
    </>
  );
};
