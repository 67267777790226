import './PageNotFound.scss';
import React, { Component } from 'react';
import { Footer } from '../components/footer/Footer';
import { ContextProvider } from "../contexts/Context";
import { CustomNavbar } from "../components/custom-navbar/CustomNavbar";

export default class PageNotFound extends Component {
  render() {
    return (
      <div id="main">
        <ContextProvider>
          <CustomNavbar />
        </ContextProvider>
        <div className="fof">
                        <h1>Apologies! Looks like your URL is not valid, please click <a href="/">here</a> to start from home page!</h1>
        </div>
        <Footer />
      </div>
    );
  }
}