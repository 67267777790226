import { Item, ItemDetails } from './components/item-card/ItemCard';
import {
  CPUBrands,
  GPUBrands,
  StorageTypes,
} from './components/sidebar/filterInterfaces';

export const dummyitems: Item[] = [
  {
    sku: '1',
    merchantsItemId: null,
    itemName:
      '- Samsung - Geek Squad Certified Refurbished ATIV Book 9 Spin 13.3\\" Touch-Screen Laptop - Intel Core i7 - 8GB Memory - 256GB SSD - Pure Black',
    regularPrice: '1199.99',
    salePrice: '1199.99',
    itemShortDescr: null,
    longDescr: null,
    description: '',
    itemUrl: 'https://api.bestbuy.com/click/-/4766300/pdp',
    upc: null,
    imageUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/4766/4766300_sd.jpg',
    thumbnailImageUrl: null,
    logoFileName: null,
    cpuSpeed: 'null',
    ramSize: '8',
    storageSize: '256',
    screenSize: '13.3 inches',
    screenResolution: '3200 x 1800',
    websiteCode: 'BB',
    affiliateId: null,
    merchantUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/4766/4766300_sd.jpg',
    cpuBrand: CPUBrands.INTEL,
  },
  {
    sku: '2',
    merchantsItemId: null,
    itemName:
      '- Dell - Latitude 14\\" Refurbished Laptop - Intel Core i5 - 8GB Memory - 128GB Solid State Drive - Gray',
    regularPrice: '288.99',
    salePrice: '288.99',
    itemShortDescr: null,
    longDescr: null,
    description: '',
    itemUrl: 'https://api.bestbuy.com/click/-/5581172/pdp',
    upc: null,
    imageUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/5581/5581172_sa.jpg',
    thumbnailImageUrl: null,
    logoFileName: null,
    cpuSpeed: 'null',
    ramSize: '8',
    storageSize: '128',
    screenSize: '14 inches',
    screenResolution: '1366 x 768',
    websiteCode: 'WM',
    affiliateId: null,
    merchantUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/4766/4766300_sd.jpg',
    cpuBrand: CPUBrands.INTEL,
  },
  {
    sku: '3',
    merchantsItemId: null,
    itemName:
      '- HP - EliteBook 14\\" Refurbished Laptop - Intel Core i5 - 8GB Memory - 128GB Solid State Drive - Silver',
    regularPrice: '315.99',
    salePrice: '315.99',
    itemShortDescr: null,
    longDescr: null,
    description: '',
    itemUrl: 'https://api.bestbuy.com/click/-/5581196/pdp',
    upc: null,
    imageUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/5581/5581196_sd.jpg',
    thumbnailImageUrl: null,
    logoFileName: null,
    cpuSpeed: 'null',
    ramSize: '8',
    storageSize: '128',
    screenSize: '14 inches',
    screenResolution: '1366 x 768',
    websiteCode: 'DELL',
    affiliateId: null,
    merchantUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/4766/4766300_sd.jpg',
    cpuBrand: CPUBrands.INTEL,
  },
  {
    sku: '4',
    merchantsItemId: null,
    itemName:
      '- MacBook Air 13.3\\" Laptop - Apple M1 chip - 8GB Memory - 256GB SSD (Latest Model) - Space Gray',
    regularPrice: '999.99',
    salePrice: '999.99',
    itemShortDescr: null,
    longDescr: null,
    description: '',
    itemUrl: 'https://api.bestbuy.com/click/-/5721600/pdp',
    upc: null,
    imageUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/5721/5721600_sd.jpg',
    thumbnailImageUrl: null,
    logoFileName: null,
    cpuSpeed: 'null',
    ramSize: '8',
    storageSize: '256',
    screenSize: '13.3 inches',
    screenResolution: '2560 x 1600',
    websiteCode: 'BB',
    affiliateId: null,
    merchantUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/4766/4766300_sd.jpg',
    cpuBrand: CPUBrands.INTEL,
  },
  {
    sku: '5',
    merchantsItemId: null,
    itemName:
      '- Apple - MacBook Pro® - 13\\" Display - Intel Core i5 - 8 GB Memory - 128GB Flash Storage - Silver',
    regularPrice: '1299.99',
    salePrice: '1169.99',
    itemShortDescr: null,
    longDescr: null,
    description: '',
    itemUrl: 'https://api.bestbuy.com/click/-/5721726/pdp',
    upc: null,
    imageUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/5721/5721726_sd.jpg',
    thumbnailImageUrl: null,
    logoFileName: null,
    cpuSpeed: 'null',
    ramSize: '8',
    storageSize: '128',
    screenSize: '13.3 inches',
    screenResolution: '2560 x 1600',
    websiteCode: 'BB',
    affiliateId: null,
    merchantUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/4766/4766300_sd.jpg',
    cpuBrand: CPUBrands.INTEL,
  },
  {
    sku: '6',
    merchantsItemId: null,
    itemName: '- XPS 13 Laptop',
    regularPrice: '1169.99',
    salePrice: '999.99',
    itemShortDescr: null,
    longDescr: null,
    description: '',
    itemUrl:
      'https://www.dell.com/en-us/member/shop/dell-laptops/xps-13-laptop/spd/xps-13-9310-laptop/xn9310cto230h',
    upc: null,
    imageUrl:
      'https://i.dell.com/is/image/DellContent//content/dam/global-site-design/product_images/dell_client_products/notebooks/xps_notebooks/xps_13_9310/general/xps-13_black_open-up-left-v2.jpg?fmt=png-alpha&pscan=auto&scl=1&hei=402&wid=664&qlt=100,0&resMode=sharp2&size=664,402',
    thumbnailImageUrl: null,
    logoFileName: null,
    cpuSpeed: '4.10',
    ramSize: '8',
    storageSize: '256',
    screenSize: '13.4 inches',
    screenResolution: '1920 x 1200',
    websiteCode: 'DELL',
    affiliateId: null,
    merchantUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/4766/4766300_sd.jpg',
    cpuBrand: CPUBrands.INTEL,
  },
  {
    sku: '7',
    merchantsItemId: null,
    itemName: '- Alienware m15 R6 Gaming Laptop',
    regularPrice: '1849.99',
    salePrice: '1616.99',
    itemShortDescr: null,
    longDescr: null,
    description: '',
    itemUrl:
      'https://www.dell.com/en-us/member/shop/gaming-laptops/alienware-m15-r6-gaming-laptop/spd/alienware-m15-r6-laptop/wnr6m15fclys',
    upc: null,
    imageUrl:
      'https://i.dell.com/is/image/DellContent//content/dam/global-site-design/product_images/dell_client_products/notebooks/alienware_notebooks/m15_r6_non-touch_non-tobii/media-gallery/awm15_r6_15_cnb_00000ff090_gy.psd?fmt=png-alpha&pscan=auto&scl=1&hei=402&wid=402&qlt=100,0&resMode=sharp2&size=402,402',
    thumbnailImageUrl: null,
    logoFileName: null,
    cpuSpeed: '4.60',
    ramSize: '16',
    storageSize: '512',
    screenSize: '15.6 inches',
    screenResolution: '1920 x 1080',
    websiteCode: 'DELL',
    affiliateId: null,
    merchantUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/4766/4766300_sd.jpg',
    cpuBrand: CPUBrands.INTEL,
  },
  {
    sku: '8',
    merchantsItemId: null,
    itemName: '- XPS 13 Laptop',
    regularPrice: '1969.99',
    salePrice: '1538.59',
    itemShortDescr: null,
    longDescr: null,
    description: '',
    itemUrl:
      'https://www.dell.com/en-us/member/shop/dell-laptops/xps-13-touch-laptop/spd/xps-13-9310-laptop/xn9310eyrhh',
    upc: null,
    imageUrl:
      'https://i.dell.com/is/image/DellContent//content/dam/global-site-design/product_images/dell_client_products/notebooks/xps_notebooks/xps_13_9310/general/xps-13_black_open-up-left-v2.jpg?fmt=png-alpha&pscan=auto&scl=1&hei=402&wid=664&qlt=100,0&resMode=sharp2&size=664,402',
    thumbnailImageUrl: null,
    logoFileName: null,
    cpuSpeed: '5.00',
    ramSize: '16',
    storageSize: '512',
    screenSize: '13.4 inches',
    screenResolution: '3456 x 2160',
    websiteCode: 'DELL',
    affiliateId: null,
    merchantUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/4766/4766300_sd.jpg',
    cpuBrand: CPUBrands.INTEL,
  },
  {
    sku: '9',
    merchantsItemId: null,
    itemName: '- XPS 15 9500 Laptop',
    regularPrice: '2199.99',
    salePrice: '1665.99',
    itemShortDescr: null,
    longDescr: null,
    description: '',
    itemUrl:
      'https://www.dell.com/en-us/member/shop/dell-laptops/xps-15-touch-laptop/spd/xps-15-9500-laptop/xn9500ecxos',
    upc: null,
    imageUrl:
      'https://i.dell.com/is/image/DellContent//content/dam/global-asset-library/Products/Notebooks/XPS/15_9500_touch/xs9500t_cnb_00055lf110_bk.psd?fmt=png-alpha&pscan=auto&scl=1&hei=402&wid=712&qlt=100,0&resMode=sharp2&size=712,402',
    thumbnailImageUrl: null,
    logoFileName: null,
    cpuSpeed: '5.00',
    ramSize: '16',
    storageSize: '512',
    screenSize: '15.6 inches',
    screenResolution: '3840 x 2400',
    websiteCode: 'DELL',
    affiliateId: null,
    merchantUrl:
      'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/4766/4766300_sd.jpg',
    cpuBrand: CPUBrands.INTEL,
  },
];

export const dummydetails: ItemDetails = {
  name: 'Microsoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - Sandstone Microsoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - Sandstone Microsoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - SandstoneMicrosoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - Sandstone Microsoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - Sandstone vMicrosoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - SandstoneMicrosoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - SandstoneMicrosoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - SandstoneMicrosoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - SandstoneMicrosoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - SandstoneMicrosoft - Surface Laptop Go - 12.4" Touch-Screen - Intel 10th Generation Core i5 - 8GB Memory - 256GB Solid State Drive - Sandstone',
  description: null,
  salePrice: 629.99,
  regularPrice: 899.99,
  upc: '889842671278',
  sku: '10',
  modelNumber: 'THJ-00035',
  merchantURL: 'https://api.bestbuy.com/click/-/6428994/pdp',
  imageURL:
    'https://pisces.bbystatic.com/prescaled/500/500/image2/BestBuy_US/images/products/6428/6428994_sd.jpg',
  secondaryImageURL:
    'https://pisces.bbystatic.com/prescaled/160/220/image2/BestBuy_US/images/products/6428/6428994_sd.jpg',
  thumbnailImageURL:
    'https://pisces.bbystatic.com/prescaled/108/54/image2/BestBuy_US/images/products/6428/6428994_sd.jpg',
  color: 'Sandstone',
  secondaryMarket: null,
  condition: 'New',
  depthInInches: 10.95,
  heightInInches: 0.62,
  widthInInches: 8.1,
  weight: 2.45,
  freeShipping: 'true',
  brand: 'Microsoft',
  category: 'COMPUTERS',
  categoryPath: null,
  websiteCode: 'BB',
  retailersDisclaimer: null,
  dimensions: null,
  misc: null,
  screenSize: '12.4 inches',
  screenResolution: '1536 x 1024',
  touchScreen: 'Yes',
  opticalDrives: 'None',
  storageSize: '256',
  storageType: StorageTypes.SSD,
  supportedWireless: null,
  wirelessProtocol: null,
  ethernet: 'no',
  bluetooth: null,
  manufacturerWarrantyParts: '1-year limited hardware warranty',
  manufacturerWarrantyLabor: 'None',
  ieee1394: null,
  totalUSBPorts: 2,
  usb2Ports: null,
  usb3Ports: null,
  usbTypeCPorts: null,
  thunderbolt2Ports: 'N',
  thunderbolt3Ports: 'N',
  thunderbolt4Ports: 'N',
  cpuSpeed: 'null',
  cpuModelNumber: 'Intel 10th Generation Core i5 1035G1',
  cpuCores: null,
  cpuBrand: CPUBrands.INTEL,
  graphicsType: 'None',
  gpuBrand: GPUBrands.INTEL,
  osName: 'Windows 10 Home in S mode',
  osArchitecture: null,
  ramSize: '8',
  ramType: 'LPDDR4X',
  cameraHighestResolution: 720,
  mic: 'Yes',
  mediaCardReader: 'No',
  configurable: 'U',
  batteryLife: null,
  audio: null,
};
