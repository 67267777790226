import React, { useCallback, useContext } from "react";
import "./Home.scss";
import { SideBar } from "../components/sidebar/SideBar";
import { ItemsList } from "../components/items-list/ItemsList";
import { NoItemFound } from "../components/no-item-found/NoItemFound";
import ItemsContext from "../contexts/ItemsContext";
import { CompareTable } from "../components/compare-table/CompareTable";
import { useSearchParams } from "react-router-dom";
import { ContextProvider } from "../contexts/Context";
import CookieConsent from "react-cookie-consent";
import { Footer } from '../components/footer/Footer';
import { CustomNavbar } from "../components/custom-navbar/CustomNavbar";
import ScreenSizeContext from "../contexts/ScreenSizeContext";

export const openSmallerWindow = () => {
  window.open("./terms_and_conditions.html", "Terms and Conditions", "resizable,height=200,width=400");
  return false;
};

export const Home = () => {
  const itemsContext = useContext(ItemsContext);
  const [searchParams] = useSearchParams();
  const screenSizeContext = useContext(ScreenSizeContext);
  const isCompare = useCallback((): boolean => {
    const searchParamsString = searchParams.toString();
    return searchParamsString.includes("skus") && searchParamsString.includes("websitecodes");
  }, [searchParams]);

  return (
    <div id="home" className="home">
      <ContextProvider>
        {(screenSizeContext.isScreenWidthLarge ||
          screenSizeContext.isScreenWidthExtraLarge) && <SideBar />}

        <CustomNavbar />
      </ContextProvider>
      <div className="home-content">{isCompare() ? <CompareTable /> : itemsContext?.items.length > 0 ? <ItemsList items={itemsContext?.items} /> : <NoItemFound />}</div>
      <CookieConsent location="bottom" buttonText="I agree" cookieName="pricewired-tc-consent">
        {" "}
        DISCLAIMER: The product information provided on this platform is sourced from various retailers and does not constitute proprietary content of PriceWired. We do not claim ownership or endorse the accuracy of this information. Users are encouraged to verify details with the respective retailers and review their terms of service regarding data usage.
        PriceWired is not responsible for any inaccuracies or discrepancies in the product information obtained from external sources. Please read our{" "}
        <a href="#" onClick={() => openSmallerWindow()}>
          terms and conditions
        </a>{" "}
        and exit if you do not agree to any part of it.
      </CookieConsent>
    </div>
  );
};
