import React, { useCallback, useMemo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import {
  BrowserRouter,
  Routes,
  Route,
  useSearchParams,
} from 'react-router-dom';
import { Home } from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import { CustomNavbar } from './components/custom-navbar/CustomNavbar';
// import { TopBar } from './components/topbar/TopBar';
import useWindowSize from '@rooks/use-window-size';
import ScreenSizeContext from './contexts/ScreenSizeContext';
import { Footer } from './components/footer/Footer';
import ItemsContext, { ItemsContextInterface } from './contexts/ItemsContext';
//import OffCanvasContext, { OffCanvasContextInterface } from './contexts/OffCanvasContext';
import {ContextProvider} from './contexts/Context';
import FiltersContext, {
  FiltersContextInterface,
} from './contexts/FiltersContext';
import { Item } from './components/item-card/ItemCard';
import {
  CPUBrands,
  Retailers,
  GPUBrands,
  SearchKeys,
  SortTypes,
  RAMSizes,
  StorageSizes,
  StorageTypes,
} from './components/sidebar/filterInterfaces';
import ItemsToCompareContext, {
  ItemsToCompareContextInterface,
} from './contexts/ItemsToCompareContext';
import { CompareSelections } from './components/compare-selections/CompareSelections';

const EXTRA_SMALL = 576; /* xs */
const LARGE_WIDTH = 992; /* >= LARGE_WIDTH == md in Bootstrap */
const EXTRA_LARGE_WIDTH = 1200;
/* const reload = () => window.location.reload(); */
const Container = () => {
  const { innerWidth } = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();
  const isScreenWidthXSmall = useCallback((): boolean => {
    return (innerWidth ?? 0) <= EXTRA_SMALL;
  }, [innerWidth]);
  
  const isScreenWidthLarge = useCallback((): boolean => {
    return (innerWidth ?? 0) >= LARGE_WIDTH;
  }, [innerWidth]);

  const isScreenWidthExtraLarge = useCallback((): boolean => {
    return (innerWidth ?? 0) >= EXTRA_LARGE_WIDTH;
  }, [innerWidth]);

  const ScreenSizeContextValue = useMemo(() => {
    return {
      isScreenWidthLarge: isScreenWidthLarge(),
      isScreenWidthExtraLarge: isScreenWidthExtraLarge(),
      isScreenWidthXSmall: isScreenWidthXSmall(),
      innerWidth: innerWidth ?? 0,
    };
  }, [innerWidth]);
  /* const [showOffCanvas, setShowOffCanvas] = useState<boolean>(false);
  const OffCanvasContextValue = useMemo((): OffCanvasContextInterface => {
    return {
      showOffCanvas,
      setShowOffCanvas,
    };
  }, [showOffCanvas]);*/  
  // Items
  const [items, setItems] = useState<Item[]>([]);
  const ItemsContextValue = useMemo((): ItemsContextInterface => {
    return {
      items,
      setItems,
    };
  }, [items]);

  // Items
  const [itemsToCompare, setItemsToCompare] = useState<Item[]>([]);
  const ItemsToCompareContextValue =
    useMemo((): ItemsToCompareContextInterface => {
      return {
        itemsToCompare,
        setItemsToCompare,
      };
    }, [itemsToCompare]);

  const getSortParam = () =>{
      const paramVal = searchParams.get('so');
      switch (paramVal) {
        case SortTypes.PRICE_DESCENDING:
        return(SortTypes.PRICE_DESCENDING)
        case SortTypes.PRICE_ASCENDING:
        return(SortTypes.PRICE_ASCENDING)
        case SortTypes.RAM_DESCENDING:
        return(SortTypes.RAM_DESCENDING)
        case SortTypes.RAM_ASCENDING:
        return(SortTypes.RAM_ASCENDING)
        case SortTypes.CPU_SPEED_DESCENDING:
        return(SortTypes.CPU_SPEED_DESCENDING)
        case SortTypes.CPU_SPEED_ASCENDING:
        return(SortTypes.CPU_SPEED_ASCENDING)
        case SortTypes.STORAGE_SIZE_DESCENDING:
        return(SortTypes.STORAGE_SIZE_DESCENDING)
        case SortTypes.STORAGE_SIZE_ASCENDING:
        return(SortTypes.STORAGE_SIZE_ASCENDING)
        default:
          return(SortTypes.PRICE_ASCENDING)
      }
  }
  // Filters
  // To add a new filter, a new state with its value has to be added here
  // Then the new state has to be bind to a form element (input, select, etc)
  // Name
  const [itemClickedSku, setItemClickedSku] = useState<string>('');

  const [sortBy, setSortBy] = useState<SortTypes>( getSortParam() || SortTypes.PRICE_ASCENDING);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);

  const [itemName, setItemName] = useState<string>('');

  // Price
  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);

  // RAM Size
   const [minRAM, setMinRAM] = useState<number>(0);
   const [maxRAM, setMaxRAM] = useState<number>(0);

  // CPU Speed
  const [minCPU, setMinCPU] = useState<number>(0);
  const [maxCPU, setMaxCPU] = useState<number>(0);

  // CPU Brands
  const [CPUAMD, setCPUAMD] = useState<boolean>(false);
  const [CPUIntel, setCPUIntel] = useState<boolean>(false);
  const [CPUApple, setCPUApple] = useState<boolean>(false);
  const [CPUMediaTek, setCPUMediaTek] = useState<boolean>(false);

  // Retailer
  const [RetailerWalmart, setRetailerWalmart] = useState<boolean>(false);
  const [RetailerDell, setRetailerDell] = useState<boolean>(false);
  const [RetailerBestBuy, setRetailerBestBuy] = useState<boolean>(false);
  
  // GPU Brands
  const [GPUAMD, setGPUAMD] = useState<boolean>(false);
  const [GPUIntel, setGPUIntel] = useState<boolean>(false);
  const [GPUApple, setGPUApple] = useState<boolean>(false);
  const [GPUNvidia, setGPUNvidia] = useState<boolean>(false);
  const [GPUMediaTek, setGPUMediaTek] = useState<boolean>(false);

  // Storage Type
  const [HDD, setHDD] = useState<boolean>(false);
  const [SSD, setSSD] = useState<boolean>(false);

  // Storage Size
  const [Any, setAny] = useState<boolean>(true);
  const [below256GB, setBelow256GB] = useState<boolean>(false);
  const [between256GBn512GB, setBetween256GBn512GB] = useState<boolean>(false);
  const [between512GBn1TB, setBetween512GBn1TB] = useState<boolean>(false);
  const [above1TB, setAbove1TB] = useState<boolean>(false);
  
  const [newSearch, setNewSearch] = useState<boolean>(false);

  const getSelectedCPUBrandsList = useCallback((): string => {
    let str = '';
    if (CPUAMD) str += `,${CPUBrands.AMD}`;
    if (CPUIntel) str += `,${CPUBrands.INTEL}`;
    if (CPUApple) str += `,${CPUBrands.APPLE}`;
    if (CPUMediaTek) str += `,${CPUBrands.MEDIATEK}`;

    // Replaces the first commma
    return str.replace(',', '');
  }, [CPUAMD, CPUIntel, CPUApple, CPUMediaTek]);

  const getSelectedRetailersList = useCallback((): string => {
    let str = '';
    if (RetailerWalmart) str += `,${Retailers.Walmart}`;
    if (RetailerDell) str += `,${Retailers.DELL}`;
    if (RetailerBestBuy) str += `,${Retailers.BestBuy}`;

    // Replaces the first commma
    return str.replace(',', '');
  }, [RetailerWalmart, RetailerDell, RetailerBestBuy]);
  
  const getSelectedGPUBrandsList = useCallback((): string => {
    let str = '';
    if (GPUAMD) str += `,${GPUBrands.AMD}`;
    if (GPUIntel) str += `,${GPUBrands.INTEL}`;
    if (GPUApple) str += `,${GPUBrands.APPLE}`;
    if (GPUNvidia) str += `,${GPUBrands.NVIDIA}`;
    if (GPUMediaTek) str += `,${GPUBrands.MEDIATEK}`;

    // Replaces the first commma
    return str.replace(',', '');
  }, [GPUAMD, GPUIntel, GPUApple, GPUNvidia, GPUMediaTek]);

  const getSelectedStorageTypesList = useCallback((): string => {
    let str = '';
    if (HDD) str += `,${StorageTypes.HDD}`;
    if (SSD) str += `,${StorageTypes.SSD}`;

    // Replaces the first commma
    return str.replace(',', '');
  }, [HDD, SSD]);

  const getSelectedStorageSizesList = useCallback((): string => {
    let str = '';
    if (Any) {
        str += `,${StorageSizes.ANY}`;
    } else if (below256GB) {
        str += `,${StorageSizes.BELOW256GB}`;
    } else if (between256GBn512GB) {
        str += `,${StorageSizes.BETWEEN256GBN512GB}`;
    } else if (between512GBn1TB) {
        str += `,${StorageSizes.BETWEEN512GBN1TB}`;
    } else if (above1TB) {
        str += `,${StorageSizes.ABOVE1TB}`;
    } /*else { //-- this is causing a potential race condition! it sets to Any when nothing in URL but fails to set radio when correct value exits
        str += `,${StorageSizes.ANY}`;
        setAny(true);
    }*/
    // Replaces the first commma
    return str.replace(',', '');
  }, [Any, below256GB, between256GBn512GB, between512GBn1TB, above1TB]);

  const clearFilters = async (): Promise<void> => {
    setMinPrice(0);
    setMaxPrice(0);

    // RAM Size
    setMinRAM(0);
    setMaxRAM(0);

    // CPU Speed
    setMinCPU(0);
    setMaxCPU(0);

    // CPU Brands
    setCPUAMD(false);
    setCPUIntel(false);
    setCPUApple(false);
    setCPUMediaTek(false);

    // GPU Brands
    setGPUAMD(false);
    setGPUIntel(false);
    setGPUApple(false);
    setGPUNvidia(false);
    setGPUMediaTek(false);

    //Retailers
    setRetailerWalmart(false);
    setRetailerDell(false);
    setRetailerBestBuy(false);

    // Storage Type
    setHDD(false);
    setSSD(false);

    // Storage Size
    setAny(true);
    setBelow256GB(false);
    setBetween256GBn512GB(false);
    setBetween512GBn1TB(false);
    setAbove1TB(false);
  };

  const setQueryParams = useCallback(async (): Promise<void> => {

    setNewSearch(true);

    const keys: SearchKeys = {
      pName: itemName,
      ret: getSelectedRetailersList(),
      prl: minPrice == 0 ? '' : minPrice,
      prh: maxPrice == 0 ? '' : maxPrice,
      raml: minRAM == 0 ? '' : minRAM,
      ramh: maxRAM == 0 ? '' : maxRAM,
      cpul: minCPU == 0 ? '' : minCPU,
      cpuh: maxCPU == 0 ? '' : maxCPU,
      cpubr: getSelectedCPUBrandsList(),
      gpubr: getSelectedGPUBrandsList(),
      styp: getSelectedStorageTypesList(),
      ssz: getSelectedStorageSizesList(),
      so: sortBy,
      //   pno  : page number starts at 1, if the number provided is > last page, last page will be served. page size is set at 25 on the server
      pno: pageNumber,
    };
    const params = Object.keys(keys)
      .map((key) => `${key}=${keys[key as keyof SearchKeys]}`)
      .join('&');
    // window.history.replaceState({}, '', newurl);
    setSearchParams(params);
  }, [
    getSelectedCPUBrandsList,
    getSelectedGPUBrandsList,
	getSelectedRetailersList,
    getSelectedStorageTypesList,
    getSelectedStorageSizesList,
    itemName,
    minPrice,
    maxPrice,
    minRAM,
    maxRAM,
    minCPU,
    maxCPU,
    sortBy,
    pageNumber,
  ]);

  const SidebarFiltersContextValue = useMemo((): FiltersContextInterface => {
    return {
      itemClickedSku,
      setItemClickedSku,

      clearFilters,
      setQueryParams,

      sortBy,
      setSortBy,

      pageNumber,
      setPageNumber,

      numberOfPages,
      setNumberOfPages,

      itemName,
      setItemName,

      minPrice,
      setMinPrice,
      maxPrice,
      setMaxPrice,

      // RAM Size
      minRAM,
      setMinRAM,
      maxRAM,
      setMaxRAM,

      // CPU Speed
      minCPU,
      setMinCPU,
      maxCPU,
      setMaxCPU,

      // CPU Brands
      CPUAMD,
      setCPUAMD,
      CPUIntel,
      setCPUIntel,
      CPUApple,
      setCPUApple,
      CPUMediaTek,
      setCPUMediaTek,

      // GPU Brands
      GPUAMD,
      setGPUAMD,
      GPUIntel,
      setGPUIntel,
      GPUApple,
      setGPUApple,
      GPUNvidia,
      setGPUNvidia,
      GPUMediaTek,
      setGPUMediaTek,
      //Retailer
      RetailerWalmart,
      setRetailerWalmart,
      RetailerDell,
      setRetailerDell,
      RetailerBestBuy,
      setRetailerBestBuy,

      // Storage Type
      HDD,
      setHDD,
      SSD,
      setSSD,

      // Storage Size
      Any,
      setAny,
      below256GB,
      setBelow256GB,
      between256GBn512GB,
      setBetween256GBn512GB,
      between512GBn1TB,
      setBetween512GBn1TB,
      above1TB,
      setAbove1TB,
      
      newSearch,
      setNewSearch,
    };
  }, [
    itemClickedSku,
    itemName,
    sortBy,
    pageNumber,
    numberOfPages,
    minPrice,
    maxPrice,
    minRAM,
    maxRAM,
    minCPU,
    maxCPU,
    CPUAMD,
    CPUIntel,
    CPUApple,
    CPUMediaTek,
    GPUAMD,
    GPUIntel,
    GPUApple,
    GPUNvidia,
    GPUMediaTek,
	RetailerWalmart,
	RetailerDell,
	RetailerBestBuy,
    HDD,
    SSD,
    Any,
    below256GB,
    between256GBn512GB,
    between512GBn1TB,
    above1TB,
    newSearch,
  ]);
  
  const isCompare = useCallback((): boolean => {
    const searchParamsString = searchParams.toString();
    return (
      searchParamsString.includes('skus') &&
      searchParamsString.includes('websitecodes')
    );
  }, [searchParams]);

  return (
    <ScreenSizeContext.Provider value={ScreenSizeContextValue}>
      <FiltersContext.Provider value={SidebarFiltersContextValue}>
        <ItemsContext.Provider value={ItemsContextValue}>
          <ItemsToCompareContext.Provider value={ItemsToCompareContextValue}>
            <Stack className="App">
              {/* <BrowserRouter> */}
              {/* {ScreenSizeContextValue.isScreenWidthLarge && <TopBar />} */}
              {/* <ContextProvider>  
                <CustomNavbar />
              </ContextProvider> */}
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={ <PageNotFound/>} />
              </Routes>
              {/* <Footer /> */}
              {itemsToCompare.length > 0 && !isCompare() && (
                <CompareSelections />
              )}
              {/* </BrowserRouter> */}
            </Stack>
          </ItemsToCompareContext.Provider>
        </ItemsContext.Provider>
      </FiltersContext.Provider>
    </ScreenSizeContext.Provider>
  );
};
const App = () => {
  return (
    <BrowserRouter>
      <Container />
    </BrowserRouter>
  );
};
export default App;
