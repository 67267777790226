import React from 'react';
import './Footer.scss';
{/* This code is a copy from home.tsx - need to figure out a better way*/}
export const openSmallerWindow = () => {
    const w = window.innerWidth / 2;
    const h = window.innerHeight / 2;
    window.open('./terms_and_conditions.html','Terms and Conditions','resizable,top=100, left=100, height=' + h + ',width=' + w);
    return false;
};
export const Footer = () => {
  return <p><div className="footer"> © 2022-2024 Cerulean LLC. All Rights reserved.</div>
        <div className="footer"><a href="#" onClick={() => openSmallerWindow()}>Terms and conditions</a></div></p>
};
