import React, { useState } from "react";
import PropTypes from 'prop-types';
  
export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
    const [showOffCanvas, setShowOffCanvas] = useState(false);
  
    return (
        <Context.Provider value={{ showOffCanvas, setShowOffCanvas }}>
            {children}
        </Context.Provider>
    );
};

ContextProvider.propTypes = {
children: PropTypes.node.isRequired,
};