import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ItemColumn } from '../../item-column/ItemColumn';
import { Item } from '../item-card/ItemCard';
import './ItemDetailsModal.scss';

export const ItemDetailsModal = ({
  show,
  setShow,
  itemToShow,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  itemToShow?: Item;
}) => {
  const handleClose = () => setShow(false);
  //   console.log('itemDetails: ', itemDetails);

  return (
    
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size='xl'  
        scrollable={true} 
      >
        <Modal.Header closeButton>
          <img
            alt="retailer laptop image"
            src="images/pwlogo_blue.png"
            className="d-inline-block align-top"
          />
        </Modal.Header>
        <Modal.Body>
          {itemToShow && <ItemColumn item={itemToShow} modal rowsToHide={[]} />}
        </Modal.Body>
      </Modal>

  );
};
