import React, { useContext, useEffect, useState } from 'react';
import {  Row, Col, Dropdown, Pagination } from 'react-bootstrap';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';
import FiltersContext from '../../contexts/FiltersContext';
import { Item, ItemCard } from '../item-card/ItemCard';
import { SortTypes } from '../sidebar/filterInterfaces';
import './ItemsList.scss';
import { useDebouncedCallback } from 'use-debounce';
import { ItemDetailsModal } from '../item-details-modal/ItemDetailsModal';
import { Footer } from '../footer/Footer';
import Alert from 'react-bootstrap/Alert';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';


export const SORT_OPTIONS: {
  type: SortTypes;
  title: string;
}[] = [
  {
    type: SortTypes.PRICE_DESCENDING,
    title: 'Price: High to Low ',
  },
  {
    type: SortTypes.PRICE_ASCENDING,
    title: 'Price: Low to High',
  },
  {
    type: SortTypes.RAM_DESCENDING,
    title: 'RAM: High to Low ',
  },
  {
    type: SortTypes.RAM_ASCENDING,
    title: 'RAM: Low to High',
  },
  {
    type: SortTypes.CPU_SPEED_DESCENDING,
    title: 'CPU Speed: High to Low ',
  },
  {
    type: SortTypes.CPU_SPEED_ASCENDING,
    title: 'CPU Speed: Low to High',
  },
  {
    type: SortTypes.STORAGE_SIZE_DESCENDING,
    title: 'Storage Size: High to Low ',
  },
  {
    type: SortTypes.STORAGE_SIZE_ASCENDING,
    title: 'Storage Size: Low to High',
  },
];

export const ItemsList = ({ items }: { items: Item[] }) => {
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [itemToShow, setItemToShow] = useState<Item | undefined>(undefined);
  const [pages, setPages] = useState<number[]>([]);
  const [viewIsGrid, setViewIsGrid] = useState(localStorage.getItem("list-view") === "grid" ? false : true);
  const isMobile = useMediaQuery({ maxWidth: 425 }); 

  const screenSizeContext = useContext(ScreenSizeContext);
  const {
    sortBy,
    setSortBy,
    numberOfPages,
    pageNumber,
    setPageNumber,
    setQueryParams,
    itemClickedSku,
    setItemClickedSku,
  } = useContext(FiltersContext);

  useEffect(() => {
    const a = Array.from({ length: numberOfPages }, (_, i) => i + 1);
    setPages(a);
  }, [numberOfPages]);

  const debouncedSetQueryParams = useDebouncedCallback(() => {
    setQueryParams();
  }, 100);

  useEffect(() => {
    const item = items.find((i) => i.sku === itemClickedSku);
    setItemToShow(undefined);
    if (item?.details) {
      setItemToShow(item);
    }
  }, [itemClickedSku, items]);

  useEffect(() => {
    localStorage.setItem("list-view", viewIsGrid ? "grid" : "list");
  }, [viewIsGrid]);

  useEffect(() => {
    setViewIsGrid(!isMobile); 
    setViewIsGrid(true); 
  }, [isMobile]);

  return (
    <div
      className={
        'items-list-container ' +
        (screenSizeContext.isScreenWidthExtraLarge
          ? 'xl-items-list-container'
          : screenSizeContext.isScreenWidthLarge
          ? 'lg-items-list-container'
          : 'small-items-list-container')
      }
    >
      <div className="item-list-controls">
        <div className="items-sort">
          {/* <div className="list-view-type">
        </div> */}
          {/*<Alert variant="success" onClose={() => setShow(false)} dismissible> */}
          {/*<Alert.Heading>Hey, nice to see you</Alert.Heading> */}
          <div className="dropdown-wrapper rounded py-2">
            <div className="sort-by">Sort By: </div>
            <Dropdown className='sort-by-dropdown'>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <div className="">{SORT_OPTIONS.find((so) => so.type === sortBy)?.title}</div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {SORT_OPTIONS.map((i) => (
                  <Dropdown.Item
                    key={i.type}
                    onClick={() => {
                      setSortBy(i.type);
                      debouncedSetQueryParams();
                    }}
                  >
                    {i.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="list-view-type-wrapper">
          <div className={`list-view-type-button ${viewIsGrid && "active"}`} onClick={() => setViewIsGrid(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M19 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M5 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M19 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            </svg>
          </div>
          <div className={`list-view-type-button ${!viewIsGrid && "active"}`} onClick={() => setViewIsGrid(false)}>
            <FontAwesomeIcon icon={faList} size="sm" />
          </div>
        </div>
      </div>


      <div className={`${viewIsGrid ? "items-grid" : "items-list"}`}>
        {items.map((i) => {
          return (
            <ItemCard
              key={i.sku}
              item={i}
              onMouseEnter={() => {
               setItemClickedSku(i.sku || '');
               setShowDetailsModal(true);
             }}
              onClick={() => {
                setItemClickedSku(i.sku || '');
                setShowDetailsModal(true);
              }}
              viewIsGrid={viewIsGrid}
            />
          );
        })}
      </div>

      <ItemDetailsModal
        show={showDetailsModal}
        setShow={setShowDetailsModal}
        itemToShow={itemToShow}
      />

      {/* Show this if items are more tan 25 */}
      <div className="items-pagination">
        <Pagination>
          {/* <Pagination.First /> */}
          {/* <Pagination.Ellipsis /> */}
          <Pagination.Prev />

          {pages.map((p) => (
            <Pagination.Item
              key={p}
              active={p === pageNumber}
              onClick={() => {
                setPageNumber(p);
                debouncedSetQueryParams();
              }}
            >
              {p}
            </Pagination.Item>
          ))}

          <Pagination.Next />
          {/* <Pagination.Last /> */}
        </Pagination>
      </div>
      <Footer />
    </div>
  );
};
