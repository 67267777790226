import React, { Dispatch, SetStateAction } from 'react';
import { Item } from '../components/item-card/ItemCard';

export interface ItemsContextInterface {
  items: Item[];
  setItems: Dispatch<SetStateAction<Item[]>>;
}

const ItemsContext = React.createContext<ItemsContextInterface>({
  items: [],
  setItems: () => null,
});

export default ItemsContext;
