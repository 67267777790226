import React, { useContext } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { useDebouncedCallback } from 'use-debounce';
import FiltersContext from '../../contexts/FiltersContext';
import './Searcher.scss';

export const Searcher = () => {
  const { setQueryParams, itemName, setItemName } = useContext(FiltersContext);

  const onInputChange = useDebouncedCallback((value: string) => {
    setItemName(value);
  }, 0);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onInputChange(event.currentTarget.value);
      setQueryParams();
    }
  };
  
  return (
    <InputGroup>
      <Button onClick={setQueryParams}>
        <BsSearch />
      </Button>
      <Form.Control
        placeholder="Search Product"
        aria-label="Search Product"
        aria-describedby="search-product-addon"
        value={itemName}
        onChange={(a) => onInputChange(a.target.value)}
		onKeyDownCapture={handleKeyDown} 
      />
    </InputGroup>
  );
};
