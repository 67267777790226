import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ItemsToCompareContext from '../../contexts/ItemsToCompareContext';
import './CompareSelections.scss';
import { Selection } from '../selection/Selection';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';
import { useSearchParams } from 'react-router-dom';

export const CompareSelections = () => {
  const screenSizeContext = useContext(ScreenSizeContext);
  const { itemsToCompare, setItemsToCompare } = useContext(
    ItemsToCompareContext,
  );
  const [items, setItems] = useState<number[]>([]);

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    const numberOfItems = screenSizeContext.isScreenWidthLarge ? 3 : 2;
    const a = Array.from({ length: numberOfItems }, (_, i) => i + 1);
    setItems(a);
  }, [screenSizeContext.innerWidth]);

  const setCompareQueryParams = useCallback(() => {
    const skus = itemsToCompare.map((i) => i.sku);
    const websiteCodes = itemsToCompare.map((i) => i.websiteCode);

    const skusString = skus.join(',');
    const websiteCodesString = websiteCodes.join(',');

    const queryParams = `?skus=${skusString}&websitecodes=${websiteCodesString}`;

    setSearchParams(queryParams);
  }, [itemsToCompare]);

  return (
    <div className="items-to-compare-panel">
      <div className="compare-row">
        <div className="compare-title">Compare ({itemsToCompare.length})</div>
        <div className="compare-actions">
          <span onClick={() => setItemsToCompare([])}>Cancel Compare</span>
          <Button
            size="sm"
            onClick={setCompareQueryParams}
            disabled={itemsToCompare.length < 2}
          >
            Compare
          </Button>
        </div>
      </div>
      <div className="items-to-compare">
        {items.map((itc, index) => (
          <Selection key={itc} item={itemsToCompare?.[index]} />
        ))}
      </div>
    </div>
  );
};
